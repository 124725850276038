<template>
  <div style="position: relative; height: 100%; width:100%; overflow: hidden">
    <span style="text-shadow: 0 0 10px rgba(0, 0, 0, 0.4) !important; z-index: 2; position: absolute; top: 0; right: 25px; font-weight: bold; color: white; font-size: 38px; text-transform: uppercase">
      Satellite mapview
    </span>
    <v-layout class="fill-height">
      <div v-show="sync" id="mapSync" style="border-right: 2px #893FF2 solid" class="flex pr-2"/>
      <div class="flex">
        <div id='wrapper' class='map'></div>
        <div id="mapSearch"/>
        <div id="beforeMap" class="map"/>
      </div>
    </v-layout>
    <v-btn style="position: absolute; bottom: 50px; right: 150px" class="elevation-1" color="white" x-small fab @click="handleScreenshot"><v-icon>mdi-camera</v-icon></v-btn>
    <div style="position: absolute; bottom: 10px; left: 50%; height: 50px; width: 220px; background-color: white; border-radius: 5px" v-if="popupArea" class="elevation-1">
      <v-layout class="fill-height" align-center justify-center>
        Area: {{area}} ha
        <v-btn style="position: absolute; top: 5px; right: 5px" icon x-small @click="closePopup"><v-icon>mdi-close</v-icon></v-btn>
      </v-layout>
    </div>
    <v-btn title="Area Measurement Tool" :color="isOpenMeasurementTool ? '#893FF2' : '#fff'" :dark="isOpenMeasurementTool" class="elevation-1" fab x-small style="position: absolute; bottom: 50px; right: 50px" @click="toggleAreaMeasurementTool"><v-icon>mdi-shape-polygon-plus</v-icon></v-btn>
    <v-card class="map-scale">
      <v-layout class="fill-height" align-center justify-center>
        1: {{scale.toFixed(0)}}
      </v-layout>
    </v-card>
    <v-card class="map-info">
      <v-layout class="fill-height" align-center justify-center>
        <v-icon x-small class="mr-2">mdi-crosshairs-gps</v-icon>
        <div style="font-size: 12px">{{ center.lng.toFixed(4) + ' ' + center.lat.toFixed(4) }}</div>
        <v-icon class="mx-2" x-small>mdi-magnify</v-icon>
        <div style="font-size: 12px">{{ zoom }}</div>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import 'mapbox-gl-compare/dist/mapbox-gl-compare.css'
import 'mapbox-gl-controls/lib/controls.css'
import 'mapbox-gl-compare'
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw'
import DrawRectangleAssisted from '@geostarters/mapbox-gl-draw-rectangle-assisted-mode'
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode'
import {CircleMode, DirectMode, DragCircleMode, SimpleSelectMode,} from '@/ultis/draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import DrawTool from "@/components/home/draw/DrawTool";
import {RulerControl, CompassControl, ZoomControl} from 'mapbox-gl-controls'
import bbox from '@turf/bbox'
import area from '@turf/area'
import sleep from "@/ultis/sleep";
import {mapState} from "@/store/ults";
import numberFormat from "@/ultis/comma";
import Store from "@/store";
import randomColor from "@/ultis/randomColor";
let popup
let map
let beforeMap
let syncMap
let diffMap
let x
let draw
let markers = []
export default {
  components: {DrawTool},
  name: "BaseMap",
  data() {
    return {
      scale: 0,
      popupArea: false,
      area: 0,
      refImage: undefined,
      isOpenMeasurementTool: false,
      layerNDVI: {},
      layerMosaic: {},
      syncImages: [],
      compareImages: [],
      disable: false,
      sync: false,
      center: {lng: 0, lat: 0},
      isBaseMap: true,
      zoom: 0,
      listImage: [],
      layers: [],
      addedLayer: undefined
    }
  },
  props: {
    dataSource: {
    },
    mode: {
    }
  },
  computed: {
    ...mapState("point", ["currentPoint"])
  },
  mounted() {
    window.mapboxgl.accessToken = 'pk.eyJ1IjoiaG9hdGllbnR1IiwiYSI6ImNrYXMwNmt4ZzA4YTIyeXAzcjZicmhsNXMifQ.9hvfCuoiO1-1cFmikE14LA'
    map = new window.mapboxgl.Map({
      container: 'mapSearch', // container id
      style: require('@/assets/MapStyle/mapstyle.json'),
      center: [103.816945, 1.355359], // starting position
      zoom: 9.5, // starting zoom,
      attributionControl: false,
      preserveDrawingBuffer: true
    })
    beforeMap = new window.mapboxgl.Map({
      container: 'beforeMap', // container id
      style: require('@/assets/MapStyle/mapstyle.json'),
      zoom: 9.5, // starting zoom,
      attributionControl: false,
      preserveDrawingBuffer: true
    })
    syncMap = new window.mapboxgl.Map({
      container: 'mapSync', // container id
      style: require('@/assets/MapStyle/mapstyle.json'),
      zoom: 9.5, // starting zoom,
      attributionControl: false,
      preserveDrawingBuffer: true
    })
    diffMap = new window.mapboxgl.Compare(beforeMap, map, '#wrapper', {})
    x = diffMap.currentPosition
    diffMap.setSlider(-50)
    draw = new MapboxDraw({
      styles: require('@/assets/MapStyle/custom-draw-style'),
      keybindings: true,
      displayControlsDefault: false,
      userProperties: true,
      controls: {
        line_string: false,
        polygon: false,
        trash: false
      },
      modes: {
        ...MapboxDraw.modes,
        draw_assisted_rectangle: DrawRectangleAssisted,
        draw_rectangle: DrawRectangle,
        draw_circle: CircleMode,
        drag_circle: DragCircleMode,
        direct_select: DirectMode,
        simple_select: SimpleSelectMode
      }
    })
    map.addControl(new window.mapboxgl.ScaleControl({
      maxWidth: 80,
      unit: 'metric'
    }), 'bottom-right')
    map.addControl(new ZoomControl(), 'bottom-right')
    map.addControl(new CompassControl(), 'bottom-right')
    map.addControl(new RulerControl(
        {
          mainColor: '#893FF2'
        }
    ), 'bottom-right')
    map.addControl(draw)
    syncMap.on('style.load', () => {
      this.addLabelLayer()
    })
    map.on('style.load', () => {
      map.resize()
      this.center = map.getCenter()
      this.zoom = map.getZoom().toFixed(2)
      if (this.dataSource){
        switch (this.dataSource) {
          case 'sentinel': this.submitZoom([103.60570070513, 1.1586987006352, 104.08848306516, 1.4707748320846])
            break
          case 'planet':
          case 'jilin': this.submitZoom([103.91033, 1.36177, 104.09282, 1.4416])
            break
        }
      }
      this.getScale()
    })
    syncMap.on('style.load', () => {
      map.resize()
      if (this.currentPoint) {
        this.addPopup()
        let data = JSON.parse(JSON.stringify(this.currentPoint))
        data.image['selected'] = true
        data.image['layoutRight'] = true
        data.image['opacity'] = 1
        data.image['layoutLeft'] = true
        data.image['displayLabel'] = true
        data.image['map_type'] = 'raster_image'
        data.image['color'] = randomColor.getColor()
        data.image['min_zoom'] = 9
        data.image['max_zoom'] = 18
        if (data.image.list_values) data.image.list_values.forEach(value => {
          value['display'] = true
        })
        this.$emit('changeListLayer', data.image)
      }
      Store.commit('point/SET_POINT', undefined)

      // if (this.currentPoint) this.addPopup()
    })
    map.on('draw.create', (e) => {
      this.calculateArea(e)
    })
    map.on('mousemove', (e) => {
      this.center = e.lngLat.wrap()
    })
    map.on('zoom', _ => {
      this.zoom = map.getZoom().toFixed(2)
    })
    map.on("move", () => {
      if (!this.disable) {
        const center = map.getCenter()
        const zoom = map.getZoom()
        const pitch = map.getPitch()
        const bearing = map.getBearing()

        this.disable = true
        syncMap.setCenter(center)
        syncMap.setZoom(zoom)
        syncMap.setPitch(pitch)
        syncMap.setBearing(bearing)
        this.disable = false
      }
    })

    map.on("moveend", () => {
      this.getScale()
    })
    syncMap.on("move", () => {
      if (!this.disable) {
        const center = syncMap.getCenter()
        const zoom = syncMap.getZoom()
        const pitch = syncMap.getPitch()
        const bearing = syncMap.getBearing()

        this.disable = true
        map.setCenter(center)
        map.setZoom(zoom)
        map.setPitch(pitch)
        map.setBearing(bearing)
        this.disable = false
      }
    })
    map.on('click', this.onMapClick)
  },
  watch: {
    dataSource (dataSource) {
      this.resetMap()
    }
  },
  destroyed() {
    if (map) {
      map.remove()
      draw = undefined
      map = undefined
    }

    if (beforeMap) {
      beforeMap.remove()
      draw = undefined
      beforeMap = undefined
    }
  },
  methods: {
    getScale() {
      let lat = map.getCenter().lat
      const R_EARTH = 591657550.5
      this.scale = (R_EARTH / Math.pow(2, this.zoom + 1)) * Math.cos((lat * Math.PI) / 180)
    },
    activatedMap () {
      const mapSearchWidth = parseInt(document.getElementById("mapSearch").style.clip
          .replaceAll(' ', '')
          .replaceAll('px', '')
          .replaceAll(')', '')
          .split(',')
          .splice(3, 1)[0])
      const beforeMapWidth = parseInt(document.getElementById("beforeMap").style.clip
          .replaceAll(' ', '')
          .replaceAll('px', '')
          .replaceAll(')', '')
          .split(',')
          .splice(1, 1)[0])
      switch (this.mode) {
        case 'overlay': return {
          maps: [map],
        }
        case 'slide' : return {
          maps: [beforeMap, map],
          width: [beforeMapWidth, mapSearchWidth]
        }
        case 'sync' : return {
          maps: [syncMap, map],
        }
        case 'time' : return {
          maps: [map],
        }
      }
    },
    async handleScreenshot () {
      const baseCanvas = document.createElement('canvas')
      const context = baseCanvas.getContext('2d')
      const canvases = []
      baseCanvas.width = 0
      const getImageObj = async (src) => {
        return new Promise(res => {
          let mapImage = new window.Image()
          mapImage.src = src
          mapImage.onload = () => {
            res(mapImage)
          }
        })
      }

      let w = 0, h = 0
      this.activatedMap().maps.forEach(currentMap => {
        let mapCanvas = currentMap.getCanvas()
        h = mapCanvas.height
        w += mapCanvas.width
        canvases.push(mapCanvas)
      })
      if (this.activatedMap().width) {
        baseCanvas.height = h
        baseCanvas.width = this.activatedMap().width.reduce((a, b) => a + b, 0)
      } else {
        baseCanvas.height = h
        baseCanvas.width = w
      }
      for (let i = 0; i < canvases.length; i++) {
        let mapImage = await getImageObj(canvases[i].toDataURL())
        let dx = 0
        if (this.activatedMap().width) {
          dx = this.activatedMap().width.slice(0, i).reduce((a, b) => a + b, 0)
          context.drawImage(mapImage, dx,0,  this.activatedMap().width[i], mapImage.height, dx,0, this.activatedMap().width[i], mapImage.height)
        } else {
          dx = canvases.slice(0, i).reduce((a, b) => a + b.width, 0)
          context.drawImage(mapImage, dx,0)
        }
      }
      let name = 'Screen_capture'
      let year = new Date().getFullYear()
      let month = new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1
      let date = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
      let hours = new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours()
      let minutes = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
      name = name + '_' + year + '_' + month + '_' + date + '_' + hours + '_' + minutes
      baseCanvas.toBlob((result) => {
        const blobUrl = URL.createObjectURL(result)
        const link = document.createElement('a')
        link.href = blobUrl
        link.setAttribute('download', name)
        link.click()
        this.sheet = false
      })
    },
    addPopup () {
      popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: true,
        closeOnMove: false,
        maxWidth: "auto"
      })
        .setLngLat([this.currentPoint.coordinate.lng, this.currentPoint.coordinate.lat])
        .setHTML('<div style="width: 250px; height: 110px; border-radius: 15px"> <div style="width: 100%; height: 20px">' +
          ' <span style="font-size: 14px; font-weight: bold; color: #893FF2; text-transform: uppercase">Point</span> ' +
          '</div>' +
          ' <div style="padding: 10px; width: 100%; height: 60px; border: 1px solid #a86ef3; text-transform: capitalize">' +
          Object.keys(this.currentPoint.data)[0] + ': ' + this.currentPoint.data[Object.keys(this.currentPoint.data)[0]] +
          '<br>' +
          'Latitude/Longtitude: ' + numberFormat.numberWithCommas(this.currentPoint.coordinate.lat.toFixed(2)) +
          '/' + numberFormat.numberWithCommas(this.currentPoint.coordinate.lng.toFixed(2)) +
          '</div>' +
          ' <div style="width: 100%; text-align: end; padding-top: 3px; display: flex">' +
          ' <button class="btn" style="height: 40px; flex: 1; background-color: #fff;"><span style="text-transform: uppercase; color: #8c47f5; font-weight: bold">Clear</span></button>' +
          ' </div>' +
          ' </div>')
        .addTo(map)
      const btnClear = document.getElementsByClassName("btn")[0]
      btnClear.addEventListener("click", () => {
        popup.remove()
      })
      map.flyTo({
        center: [this.currentPoint.coordinate.lng, this.currentPoint.coordinate.lat],
        essential: true // this animation is considered essential with respect to prefers-reduced-motion
      })
    },
    setRefImage (data) {
      this.refImage = JSON.parse(JSON.stringify(data))
    },
    closePopup () {
      this.popupArea = false
      draw.deleteAll()
    },
    async calculateArea (e) {
      await sleep(300)
      let currentArea = e.features.length ? area(e.features[0]) / 1000000 : 0
      this.area = (currentArea * 100).toFixed(4)
      this.isOpenMeasurementTool = false
      this.popupArea = true
    },
    toggleAreaMeasurementTool () {
      draw.deleteAll()
      this.area = 0
      if (draw.getMode() !== 'draw_polygon') {
        draw.changeMode('draw_polygon')
        this.isOpenMeasurementTool = true
      } else {
        this.popupArea = false
        this.isOpenMeasurementTool = false
        draw.deleteAll()
        draw.changeMode('simple_select')
      }
    },
    changeDisplayImage (image, beforeId) {
      map.removeLayer(image.id).removeSource(image.id)
      this.addImageLayer(image, beforeId)
    },
    onMapClick (e) {
      markers.forEach(marker => {
        marker.remove()
      })
      markers = []
      if (this.mode === 'time') {
        this.$emit('inspectImage', e.lngLat.lng, e.lngLat.lat)
        const marker = new mapboxgl.Marker()
            .setLngLat([ e.lngLat.lng, e.lngLat.lat])
            .addTo(map)
        markers.push(marker)
      }
    },
    updateOpacity (layer, opacity) {
      switch (layer.map_type) {
        case 'raster_image':
          if (map.getStyle().layers.some(val => val.id === layer.id )) map.setPaintProperty(layer.id, 'raster-opacity', opacity)
          if (beforeMap.getStyle().layers.some(val => val.id === layer.id )) beforeMap.setPaintProperty(layer.id, 'raster-opacity', opacity)
          if (syncMap.getStyle().layers.some(val => val.id === layer.id )) syncMap.setPaintProperty(layer.id, 'raster-opacity', opacity)
          break
        case 'vector':
          if (map.getStyle().layers.some(val => val.id === layer.id )) map.setPaintProperty(layer.id, 'line-opacity', opacity)
          if (beforeMap.getStyle().layers.some(val => val.id === layer.id )) beforeMap.setPaintProperty(layer.id, 'line-opacity', opacity)
          if (syncMap.getStyle().layers.some(val => val.id === layer.id )) syncMap.setPaintProperty(layer.id, 'line-opacity', opacity)
          break
      }
    },
    resetMap () {
      markers.forEach(marker => {
        marker.remove()
      })
      markers = []
      this.layers.forEach(layer => {
        if (map.getStyle().layers.some(val => val.id === layer.id)) map.removeLayer(layer.id).removeSource(layer.id)
      })
      this.compareImages.forEach(layer => {
        if (beforeMap.getStyle().layers.some(val => val.id === layer.id)) beforeMap.removeLayer(layer.id).removeSource(layer.id)
      })
      this.syncImages.forEach(layer => {
        if (syncMap.getStyle().layers.some(val => val.id === layer.id)) syncMap.removeLayer(layer.id).removeSource(layer.id)
      })
      this.layers = []
      this.compareImages = []
      this.syncImages = []
      if (this.dataSource){
        switch (this.dataSource) {
          case 'sentinel': this.submitZoom([103.60570070513, 1.1586987006352, 104.08848306516, 1.4707748320846])
            break
          case 'planet':
          case 'jilin': this.submitZoom([103.91033, 1.36177, 104.09282, 1.4416])
            break
        }
      }
    },
    addLabelLayer() {
      map.addLayer(require('@/assets/MapStyle/label.json'))
      beforeMap.addLayer(require('@/assets/MapStyle/label.json'))
      syncMap.addLayer(require('@/assets/MapStyle/label.json'))
    },
    toggleLabel (status) {
      let visibility = 'visible'
      if (!status) visibility = 'none'
      map.setLayoutProperty('label', 'visibility', visibility)
      beforeMap.setLayoutProperty('label', 'visibility', visibility)
      syncMap.setLayoutProperty('label', 'visibility', visibility)
    },
    syncMap () {
      this.sync = !this.sync
      this.$nextTick(() => {
        map.resize()
        syncMap.resize()
      })
      diffMap.setSlider(-10000)
    },
    changeCompare (show) {
      this.sync = false
      this.$nextTick(() => {
        map.resize()
      })
      if (show) diffMap.setSlider(x)
      else diffMap.setSlider(-500)
    },
    changeViewImage (image, position) {
      switch (position) {
        case 'left': if (image.layoutLeft) {
          beforeMap.setLayoutProperty(image.id, 'visibility', 'visible')
          syncMap.setLayoutProperty(image.id, 'visibility', 'visible')
        } else {
          beforeMap.setLayoutProperty(image.id, 'visibility', 'none')
          syncMap.setLayoutProperty(image.id, 'visibility', 'none')
        }
          break
        case 'right': if (image.layoutRight) map.setLayoutProperty(image.id, 'visibility', 'visible')
          else map.setLayoutProperty(image.id, 'visibility', 'none')
          break
      }
    },
    removeLayer (layer) {
      if (this.layers.findIndex(val => val.id === layer.id) >= 0) {
        map.removeLayer(layer.id).removeSource(layer.id)
        this.layers.splice(this.layers.findIndex(val => val.id === layer.id), 1)
      }
      if (this.syncImages.findIndex(val => val.id === layer.id) >= 0) {
        syncMap.removeLayer(layer.id).removeSource(layer.id)
        this.syncImages.splice(this.syncImages.findIndex(val => val.id === layer.id), 1)
      }
      if (this.compareImages.findIndex(val => val.id === layer.id) >= 0) {
        beforeMap.removeLayer(layer.id).removeSource(layer.id)
        this.compareImages.splice(this.compareImages.findIndex(val => val.id === layer.id), 1)
      }
    },
    // removeAllTimeLayer () {
    //   if (this.layerNDVI.id) this.removeLayerTimeSerial(this.layerNDVI.id)
    //   this.layerNDVI = {}
    //   if (this.layerMosaic.id) this.removeLayerTimeSerial(this.layerMosaic.id)
    //   this.layerMosaic= {}
    // },
    changeMosaicImage (image) {
      this.addMosaicImage(image)
    },
    removeLayerTimeSerial (layerId) {
      if (layerId) map.removeLayer(layerId).removeSource(layerId)
    },
    changeCursor (currentLayer) {
      map.on('mouseenter', currentLayer.id, () => {
        map.getCanvas().style.cursor = 'zoom-in'
      })
      map.on('mouseleave', currentLayer.id, () => {
        map.getCanvas().style.cursor = 'default'
      })
    },
    rasterLayer (image, visible, opacity) {
      let listHiddenValue = []
      if (image.list_values) image.list_values.forEach(val => {
        if (!val.display) listHiddenValue.push(val.minValue)
      })
      let tileUrl = image.tile_url
      if (listHiddenValue.length) tileUrl = tileUrl + '&list_hidden_value=' + listHiddenValue
      const layer = {
        id: image.id,
        'type': 'raster',
        'source': image.id,
        'layout': {
          'visibility': visible ? 'visible' : 'none'
        },
        'paint': {
          "raster-opacity": opacity !== undefined ? opacity : 1,
          'raster-fade-duration': 0
        }
      }
      const source = {
        'type': 'raster',
        'tiles': [tileUrl],
        'tileSize': 256,
        'maxzoom': image.max_zoom || 18,
        'minzoom': image.min_zoom || 1,
        'bounds': image.bbox
      }
      return ({layer, source})
    },
    vectorLayer (vector, visible, opacity) {
      const layer = {
        'id': vector.id,
        'type': 'line',
        'source': vector.id,
        'layout': {
          'visibility': visible ? 'visible' : 'none'
        },
        'paint': {
          'line-color': vector.color,
          'line-opacity': opacity !== undefined ? opacity : 1,
          'line-width': 3
        }
      }
      const source = {
        'type': 'geojson',
        'data': vector.geometry
      }
      return ({layer, source})
    },
    getMapboxLayer (data, visible,opacity) {
      switch (data.map_type) {
        case 'raster_image': return(this.rasterLayer(data, visible, opacity))
        case 'vector': return (this.vectorLayer(data, visible, opacity))
      }
    },
    addTimeImage (ndviImage, mosaicImage) {
      this.addNDVIImage(ndviImage)
      this.addMosaicImage(mosaicImage)
    },
    addNDVIImage (ndviImage,) {
      let mapboxLayer = this.getMapboxLayer(ndviImage, true)
      map.addSource(mapboxLayer.layer.id, mapboxLayer.source)
      map.addLayer(mapboxLayer.layer, 'label')
      this.layerNDVI = mapboxLayer.layer
    },
    addMosaicImage (mosaicImage) {
      let mapboxLayer = this.getMapboxLayer(mosaicImage, true)
      map.addSource(mapboxLayer.layer.id, mapboxLayer.source)
      map.addLayer(mapboxLayer.layer, this.layerNDVI.id)
      beforeMap.addSource(mapboxLayer.layer.id, mapboxLayer.source)
      beforeMap.addLayer(mapboxLayer.layer, this.layerNDVI.id)
      syncMap.addSource(mapboxLayer.layer.id, mapboxLayer.source)
      syncMap.addLayer(mapboxLayer.layer, this.layerNDVI.id)
      this.layers.push(mapboxLayer.layer)
      // this.layerMosaic = mapboxLayer.layer
    },
    addVectorLayer (vector, opacity) {
      const mapboxLayerLeft = this.getMapboxLayer(vector, vector.layoutLeft, opacity)
      const mapboxLayerRight = this.getMapboxLayer(vector, vector.layoutRight, opacity)
      if (!syncMap.getStyle().layers.some(val => val.id === vector.id)) {
        this.addLayerSync(mapboxLayerLeft.layer, mapboxLayerLeft.source)
        this.syncImages.push(mapboxLayerLeft.layer)
      }
      if (!beforeMap.getStyle().layers.some(val => val.id === vector.id)) {
        this.addLayerLeft(mapboxLayerLeft.layer, mapboxLayerLeft.source)
        this.compareImages.push(mapboxLayerLeft.layer)
      }
      if (!map.getStyle().layers.some(val => val.id === vector.id)) {
        this.addLayerRight(mapboxLayerRight.layer, mapboxLayerRight.source)
        this.layers.push(mapboxLayerRight.layer)
      }
    },
    addImageLayer (image, beforeId, opacity) {
      const mapboxLayerLeft = this.getMapboxLayer(image, image.layoutLeft, opacity)
      const mapboxLayerRight = this.getMapboxLayer(image, image.layoutRight, opacity)
      if (image.isSlope) this.layerNDVI = mapboxLayerLeft.layer
      if (!syncMap.getStyle().layers.some(val => val.id === image.id)) {
        this.addLayerSync(mapboxLayerLeft.layer, mapboxLayerLeft.source, beforeId)
        this.syncImages.push(mapboxLayerLeft.layer)
      }
      if (!beforeMap.getStyle().layers.some(val => val.id === image.id)) {
        this.addLayerLeft(mapboxLayerLeft.layer, mapboxLayerLeft.source, beforeId)
        this.compareImages.push(mapboxLayerLeft.layer)
      }
      if (!map.getStyle().layers.some(val => val.id === image.id)) {
        this.addLayerRight(mapboxLayerRight.layer, mapboxLayerRight.source, beforeId)
        this.layers.push(mapboxLayerRight.layer)
      }
    },
    addLayerSync (layer, source, beforeId) {
      if(!beforeId) beforeId = 'label'
      syncMap.addSource(layer.id, source)
      syncMap.addLayer(layer, beforeId)
    },
    addLayerLeft (layer, source, beforeId) {
      if(!beforeId) beforeId = 'label'
      beforeMap.addSource(layer.id, source)
      beforeMap.addLayer(layer, beforeId)
    },
    addLayerRight (layer, source, beforeId) {
      if(!beforeId) beforeId = 'label'
      map.addSource(layer.id, source)
      map.addLayer(layer, beforeId)
    },
    zoomToBound(currentImage) {
      this.submitZoom(bbox(currentImage.geometry))
    },
    submitZoom(bbox) {
      map.fitBounds(bbox, {
        'duration': 0,
        'padding': 20
      })
    },
    removeAllLayer () {
      this.layers.forEach(layer => {
        this.removeLayer(layer)
      })
      this.syncImages.forEach(layer => {
        this.removeLayer(layer)
      })
      this.compareImages.forEach(layer => {
        this.removeLayer(layer)
      })
    }
  },
}
</script>

<style scoped>
/deep/
.mapbox-control-ruler {
  position: absolute;
  border-radius: 50% !important;
  height: 30px;
  width: 30px;
  padding-top: 3px;
  padding-left: 0px;
  bottom: 0px;
  right: 320px;
  fill: #893FF2;
}
.mapbox-control-ruler button svg {
  fill: #893FF2 !important;
}


.map-info {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  z-index: 2;
  background-color: #fcfaf9;
  position: absolute;
  bottom: 10px;
  right: 200px;
  width: 220px;
  height: 30px;
}
.map-scale {
  color: #893FF2;
  font-size: 12px;
  border: 1px solid #893FF2;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  z-index: 2;
  background-color: #fcfaf9;
  position: absolute;
  bottom: 10px;
  right: 90px;
  width: 100px;
  height: 30px;
}
.map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
#mapSearch {
  width: 100%;
  height: 100%;
}

#beforeMap {
  width: 100%;
  height: 100%;
}

/*#mapSync {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/

/deep/
.mapbox-compass {
  border-radius: 50%;
}
/deep/
.mapbox-control-ruler button {
  border-radius: 50%;
  background-color: transparent;
  width: 32px !important;
  height: 32px !important;
  margin-left: 0px;
  margin-top: -3px;
}
/deep/
.mapbox-control-ruler {
  background-color: #FFFFFF;
  width: 32px !important;
  height: 32px !important;
  position: absolute;
  bottom: 40px;
  right: 90px;
}
/deep/
.mapbox-control-ruler .-active {
  background-color: #893FF2;
}
/deep/
.mapbox-control-ruler .-active svg {
  fill: #FFFFFF !important;
  width: 22px;
  height: 22px;
}
/deep/
.mapbox-compass {
  position: absolute;
  bottom: 0;
  right: 310px;
}
/deep/
.mapbox-compass button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

/deep/
.mapbox-compass svg {
  margin-right: 2px;
  margin-top: 4px;
  width: 25px;
  height: 25px;
}

/deep/
.mapbox-zoom {
  border-radius: 30px;
  box-shadow: none;
}

/deep/
.mapbox-zoom button {
  background-color: white;
  width: 30px;
  height: 30px;
}

/deep/
.mapbox-zoom button:nth-child(1) {
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
}

/deep/
.mapbox-zoom button:nth-child(2) {
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
/deep/
.mapboxgl-ctrl-scale {
  font-size: 12px;
  text-align: center;
  padding-top: 5px;
  height: 30px;
  width: 75px !important;
  background-color: white;
  border-radius: 30px;
}
/deep/
#wrapper .mapboxgl-compare {
  background-color: #893FF2;
}
</style>
