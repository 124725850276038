<template>
  <div class="d-flex fill-height justify-center flex-column px-8" style="width: 100%" v-if="currentUser.role === 'ADMIN'">
    <div style="width: 100%; height: 155px; border-bottom: 1px solid lightgrey" class="d-flex align-center justify-center flex-column">
      <v-layout align-center>
        <h1 class="d-flex"><v-icon color="#333333" size="40" class="mr-1">mdi-account-multiple</v-icon>User management</h1>
      </v-layout>
      <v-container fluid class="flex-fill overflow-y-auto" style="height: 20px; max-width: 1400px">
        <v-layout justify-end>
          <popup-create-user @getUser="getData"/>
        </v-layout>
      </v-container>
    </div>
    <v-container fluid class="flex-fill overflow-y-auto" style="height: calc(100vh - 220px); max-width: 1400px">
      <v-card height="100%" flat>
        <v-data-table
            height="100%"
            fixed-header
            style="height: 77%; border: 1px lightgrey solid"
            :headers="userHeaders"
            :items="users"
            :loading="loading"
            :items-per-page="15"
            class="row-pointer order-table"
            hide-default-footer
            :server-items-length="totalItem"
            loading-text="Loading... Please wait"
            :options.sync="options"
            :page.sync="page"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at ? new Date(item.created_at).toLocaleDateString("en-GB") : ''}}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon x-small color="green" @click="() => $refs.updateUserForm.openDialog(item)"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn icon x-small color="red" class="ml-2" @click="() => $refs.deleteUserForm.openDialog(item)"><v-icon>mdi-delete</v-icon></v-btn>
          </template>
          <template v-slot:[`item.active`]="{ item }">
            <v-checkbox
                @change="updateUser(item)"
                class="mt-0"
                hide-details
                color="#893FF2"
                v-model="item.active"
            ></v-checkbox>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination class="ma-3 mt-6" :total-visible="5" v-model="page" :length="pageCount" id="task_pagination"></v-pagination>
        </div>
      </v-card>
    </v-container>
    <PopupUpdateUser @getUser="getData" ref="updateUserForm"/>
    <PopupDeleteUser @getUser="getData" ref="deleteUserForm"/>
  </div>
</template>

<script>
import {
  getAllUser, updateUser,
} from "@/backend";
import {mapState} from "@/store/ults";
import PopupCreateUser from "@/components/user/CreateUser";
import PopupUpdateUser from "@/components/user/UpdateUser";
import PopupDeleteUser from "@/components/user/DeleteUser";
export default {
  components: {
    PopupDeleteUser,
    PopupUpdateUser,
    PopupCreateUser
  },
  data() {
    return {
      options: {},
      page: 1,
      totalItem: 100,
      pageCount: 0,
      loading: false,
      userHeaders: [
        {text: "Name", align: "left", sortable: true, value: "name",},
        { text: "Email", value: "email", sortable: false, align: "left" },
        { text: "Created at", value: "created_at", sortable: true, align: "start" },
        { text: "Role", value: "role", sortable: false, align: "start" },
        { text: "Active", value: "active", sortable: false, align: "start" },
        { text: "", value: "action", sortable: false, align: "center" }
      ],
      users: []
    };
  },
  computed: {
    ...mapState("auth", ["currentUser"])
  },
  watch: {
    options: {
      handler () {
        this.getData()
      },
      deep: true
    }
  },
  created() {
    if (this.currentUser.role !== 'ADMIN') this.$router.push({ path: '/app' })
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        let sort
        if (this.options.sortBy.length && this.options.sortBy.length) {
          if (this.options.sortDesc[0]) sort = this.options.sortBy[0]
          else sort = '-' + this.options.sortBy[0]
        }
        const res = await getAllUser({per_page: this.options.itemsPerPage, page: this.options.page, sort: sort})
        this.users = res.data.data
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
    async updateUser (user) {
      this.loading = true
      try {
        const res = await updateUser({
          id: user.id,
          data: {
            active: user.active
          }
        })
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', e.message)
      } finally {
        await this.getData()
        this.loading = false
      }
    }
  },
};
</script>
<style scoped>
/deep/
.v-chip__content {
  width: 180px !important;
}
/deep/
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: inset 0px 4px 6px -5px rgb(50 50 50 / 75%),
  inset 0px -4px 8px -5px rgb(50 50 50 / 0%);
}
/deep/
.order-table th {
  background-color: #893FF2 !important;
  color: white !important;
}
/deep/
.list-image th {
  background-color: #6686a3 !important;
}
</style>
