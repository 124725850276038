<template>
  <v-layout align-center justify-center style="height: calc(100vh - 75px); background-color: #f4ecfd" class="pa-5">
    <v-overlay :value="loading">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <div class="d-flex flex flex-column px-5 pt-5 fill-height user-info">
      <div style="flex: none; height: 220px; border-bottom: 1px solid lightgray">
        <v-layout class="fill-height" justify-center align-center column>
          <div style="width: 130px; height: 130px">
            <img :src="require('@/assets/images/default-avatar.jpg')" height="100%" width="100%"
                 style="border-radius: 50%; border: 3px solid #893FF2; padding: 4px" alt="icon"/>
          </div>
          <h3 class="mt-3" style="color: #893FF2">{{ currentUser.email }}</h3>
        </v-layout>
      </div>
      <v-form v-model="valid" ref="formData" class="d-flex flex-column fill-height">
        <v-layout column class="fill-height py-5">
          <v-layout class="fill-height">
            <div class="flex pr-4" style="flex: 1; border-right: 1px solid lightgray">
              <div style="height: 60px; width: 100%; flex: none">
                <v-layout class="fill-height" align-center>
                  <h2 style="color: #893FF2">User information</h2>
                </v-layout>
              </div>
              <span style="font-size: 14px; font-weight: bold;">Name</span>
              <div style="width: 100%;" class="pt-1">
                <v-text-field
                    class="input-login"
                    background-color="white"
                    dense
                    outlined
                    v-model="profile.name"
                    label="Name"
                    placeholder="Name"
                    style="color: black !important;"
                    :rules="[rules.requiredUserName]"
                >
                </v-text-field>
              </div>
            </div>
            <div class="flex pl-4" style="flex: 1">
              <div style="height: 60px; width: 100%; flex: none">
                <v-layout class="fill-height" align-center>
                  <v-checkbox hide-details v-model="isChangePassWord" label="Change password"/>
                </v-layout>
              </div>
              <v-card flat :disabled="!isChangePassWord">
                <span style="font-size: 14px; font-weight: bold;">Password</span>
                <div style="width: 100%;" class="pt-1">
                  <v-text-field
                      class="input-login"
                      dense
                      outlined
                      background-color="white"
                      v-model="password"
                      label="Password"
                      :rules="[rules.requiredPassword]"
                      :type="isShowPassword ? 'text' : 'password'"
                      :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                      @click:append="isShowPassword = !isShowPassword">
                  </v-text-field>
                </div>
                <span style="font-size: 14px; font-weight: bold;">Confirm Password</span>
                <div style="width: 100%;" class="pt-1">
                  <v-text-field
                      class="input-login"
                      dense
                      outlined
                      background-color="white"
                      v-model="confirmPassword"
                      label="Confirm Password"
                      :rules="[rules.requiredConfirmPassword]"
                      :type="isShowPassword ? 'text' : 'password'"
                      :disabled="!this.password"
                      :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                      @click:append="isShowPassword = !isShowPassword">
                  </v-text-field>
                </div>
              </v-card>
            </div>
          </v-layout>
        </v-layout>
      </v-form>
      <div style="flex: none; height: 55px; border-top: 1px solid lightgray">
        <v-layout class="fill-height" justify-center align-center>
          <v-btn color="#893FF2FF" dark width="180" class="mr-2" @click="$router.push({ path: '/app' })"><v-icon class="mr-1">mdi-home</v-icon>Dashboard</v-btn>
          <v-spacer/>
          <v-btn color="#893FF2FF" dark width="180" class="ml-2" @click="saveProfile"><v-icon class="mr-1">mdi-content-save</v-icon>Save profile</v-btn>
        </v-layout>
      </div>
    </div>
  </v-layout>
</template>

<script>
import {mapState} from "@/store/ults";
import {checkToken, updateProfile} from "@/backend";
import Store from "@/store";
import api from "@/api";

export default {
  name: "Profile",
  components: {},
  data() {
    return {
      isChangePassWord: false,
      loading: false,
      valid: false,
      popupForgot: false,
      rules: {
        requiredUserName: value => (!!value && !!value.trim()) || 'Name is required',
        requiredPassword: value => (!!value && !!value.trim() && /^(?=.*[!,@,#,$,%,^,&,(,),{,},\[\],:,;,<,>,?,/,~,_,+,-,=,-])(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{12,}$/.test(value)) || 'At least:   - 12 characters  - 1 lowercase  - 1 uppercase  - 1 number  - 1 special characters (!, $, #, %, ...);',
        requiredConfirmPassword: value => (!!value && !!value.trim() && value.trim() === this.password.trim()) || 'Confirm Password is invalid'
      },
      confirmPassword: undefined,
      password: undefined,
      isShowPassword: false,
      profile: {}
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  mounted() {
    this.profile = JSON.parse(JSON.stringify(this.currentUser))
  },
  methods: {
    async saveProfile () {
      try {
        this.loading = true
        await updateProfile({
          name: this.profile.name,
          password: this.isChangePassWord ? this.password : undefined,
          password_confirmation:  this.isChangePassWord ? this.confirmPassword : undefined
        })
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        if (this.isChangePassWord) {
          api.deleteToken()
          window.location.href = '/'
        }
        else await this.getMe()
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getMe () {
      try {
        this.loading = true
        let res = await checkToken()
        Store.commit('auth/SET_USER', res.data)
      } catch (e) {
      } finally {
        this.profile = JSON.parse(JSON.stringify(this.currentUser))
        location.reload()
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.user-info {
  background-color: #FFFFFF;
  max-width: 1240px;
  border: 1px solid lightgray;
  border-radius: 8px;
  overflow-y: auto;
  border: 1px solid rgba(137, 63, 242, 0.1);
  box-shadow: inset 0 0 3px #893FF2FF;
}
</style>
