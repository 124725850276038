<template>
  <v-layout column class="fill-height px-0">
    <div style="width: 100%; height: 50px" class="px-3 py-3">
      <v-layout class="fill-height" align-center>
        <h2 style="color: #893FF2; text-transform: uppercase">Data library</h2>
        <v-spacer/>
        <span @click="() => $emit('hideSideBar')" style="font-size: 14px; color: #893FF2; cursor: pointer"><v-icon color="#893FF2">mdi-chevron-double-left</v-icon>Hide Sidebar</span>
      </v-layout>
    </div>
    <v-tabs
        class="sidebar-tab"
        style="flex: none; height: 45px"
        v-model="tabs"
        fixed-tabs
        color="#893FF2"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
          href="#data"
          style="text-transform: none;"
          :style="{'background-color': tabs === 'data' ? '' : 'rgba(137,63,242,0.22)'}"
      >
        Data
      </v-tab>

      <v-tab
          href="#area"
          style="text-transform: none;"
          :style="{'background-color': tabs === 'area' ? '' : 'rgba(137,63,242,0.22)'}"
      >
        Area of interest
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs" class="fill-height">
      <v-tab-item
          value="data"
          class="fill-height"
      >
        <v-card flat class="fill-height d-flex flex-column pt-8">
          <div style="width: 100%; height: 150px;" class="py-5">
            <v-layout class="px-3" style="height: 50px">
              <div style="width: 45%" class="mr-2">
                <month-picker  @input="() => $emit('changeMonth', this.month.from, this.month.to)" :label="'From month'" :max.sync="month.to" v-model="month.from"/>
<!--                <v-select-->
<!--                    @input="() => $emit('changeMonth', this.month.from, this.month.to)"-->
<!--                    hide-details-->
<!--                    color="#893FF2"-->
<!--                    dense-->
<!--                    class="select-month"-->
<!--                    label="Start month"-->
<!--                    v-model="month.from"-->
<!--                    :items="monthSelection"-->
<!--                    item-text="name"-->
<!--                    item-value="value"-->
<!--                    return-object-->
<!--                />-->
              </div>
              <div style="width: 45%" class="ml-2">
                <month-picker @input="() => $emit('changeMonth', this.month.from, this.month.to)" :label="'To month'" :min.sync="month.from" v-model="month.to"/>
<!--                <v-select-->
<!--                    @input="() => $emit('changeMonth', this.month.from, this.month.to)"-->
<!--                    hide-details-->
<!--                    color="#893FF2"-->
<!--                    dense-->
<!--                    class="select-month"-->
<!--                    label="End month"-->
<!--                    v-model="month.to"-->
<!--                    :items="monthSelection"-->
<!--                    item-text="name"-->
<!--                    item-value="value"-->
<!--                    return-object-->
<!--                />-->
              </div>
            </v-layout>
            <v-layout style="height: 50px" class="px-3" align-center>
              <div style="width: 45%; color: #893FF2" class="mr-2">
                Group by:
              </div>
              <div style="width: 45%" class="ml-2">
                <v-card width="180" height="30">
                  <v-layout justify-center align-center class="fill-height">
                    <v-btn-toggle
                        mandatory
                        v-model="mode"
                        tile
                        color="deep-purple accent-3"
                        group
                    >
                      <v-btn
                          :style="{'border': mode === 'type'? '1px solid #893FF2' : '', 'border-radius': mode === 'type'? '5px' : '0'}"
                          value="type"
                          x-small>
                        Data type
                      </v-btn>
                      <v-btn
                          :style="{'border': mode === 'month'? '1px solid #893FF2' : '', 'border-radius': mode === 'month'? '5px' : '0'}"
                          style="border-radius: 3px"
                          value="month"
                          x-small>
                        Month
                      </v-btn>
                    </v-btn-toggle>
                  </v-layout>
                </v-card>
              </div>
            </v-layout>
          </div>
          <v-layout column class="overflow-y-auto pb-3" style="height: 100%">
            <v-expansion-panels multiple flat>
              <v-expansion-panel v-for="(group, idx) of groups" :key="idx">
                <v-expansion-panel-header>
                  <span style="text-transform: uppercase; font-weight: bold"><v-icon class="mr-3">mdi-folder-outline</v-icon>{{group.name}}</span>
                  <v-spacer/>
                  <span class="mr-2" style="text-align: end; font-size: 12px;">(Total: {{group.images.length}} image)</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list dense>
                    <v-list-item v-for="(item, index) in group.images" class="elevation-1 my-2 pl-0" :key="index" style="border-radius: 5px" @click="() => $emit('changeListLayer', item)">
                      <v-layout column>
                        <v-layout style="height: 35px" align-center>
                          <div style="border-bottom-left-radius: 5px; border-top-left-radius: 5px; height: 40px; width: 5px;" :style="{'background-color': item.color}" class="mr-3"></div>
                          <v-layout class="fill-height" align-center>
                            <span style="font-size: 13px">{{item.name}}</span>
                            <v-spacer/>
                            <FullScreenSquare :color="item.color"/>
                          </v-layout>
                        </v-layout>
                      </v-layout>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-layout>
        </v-card>
      </v-tab-item>
      <v-tab-item
          value="area"
          class="fill-height"
      >
        <v-card flat class="fill-height pb-6 pt-8">
          <v-layout column class="overflow-y-auto pb-5 pt-8 px-5" style="height: 100%">
            <v-list dense>
              <v-list-item v-for="(item, index) in areas" class="elevation-1 my-2 pl-0" :key="index" style="border-radius: 5px" @click="() => $emit('changeListLayer', item)">
                <v-layout column>
                  <v-layout style="height: 35px" align-center>
                    <div style="border-bottom-left-radius: 5px; border-top-left-radius: 5px; height: 40px; width: 5px;" :style="{'background-color': item.color}" class="mr-3"></div>
                    <v-layout class="fill-height" align-center>
                      <span style="font-size: 13px">{{item.name}}</span>
                    </v-layout>
                  </v-layout>
                </v-layout>
              </v-list-item>
            </v-list>
          </v-layout>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-layout>
</template>

<script>
import FullScreenSquare from "@/components/imagery/FullScreenSquare";
import {mapState} from "@/store/ults";
import Store from "@/store";
import MonthPicker from "@/components/MonthPicker";
export default {
  name: "DataSelection",
  components: {MonthPicker, FullScreenSquare},
  data () {
    return {
      tabs: 'data',
      mode: 'type',
      monthSelection: require('@/assets/monthSelect.json'),
      month: {
        from: undefined,
        to: undefined
      }
    }
  },
  props: {
    fromMonth: {
      type: String
    },
    toMonth: {
      type: String
    },
    groups: {type: Array, default: () => []},
    areas: {type: Array, default: () => []}
  },
  watch: {
    mode (val) {
      this.$emit('input', val)
    },
    groups (val) {
      if (!this.currentPoint) return
      this.groups.forEach(group => {
        let currentImage = group.images.find(image => image.id === this.currentPoint.image.id)
        if (currentImage) this.$emit('changeListLayer', currentImage)
      })
    }
  },
  computed: {
    ...mapState("point", ["currentPoint"]),
  },
  mounted() {
    this.month.from = this.fromMonth
    this.month.to = this.toMonth
  },
  methods: {
  }
}
</script>

<style scoped>
/deep/
.sidebar-tab .v-tabs-bar {
  height: 45px !important;
}
/deep/
.small-radio .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
  background: currentColor;
  transform: scale(0.9);
  transition: none;
}
</style>
