<template>
  <v-layout column class="pa-5 overflow-y-auto" style="height: calc(100vh - 200px)">
    <v-overlay :value="loading">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <div style="width: 100%; height: 50px" class="px-3">
      <h2 style="color: #893FF2; text-transform: uppercase">NDVI PLANT HEALTH INDICATIOR</h2>
    </div>
    <div style="width: 100%; height: 50px" class="px-3 pt-2">
      <span style="font-size: 14px; color: #636363; font-weight: 500">Adjust visibility of NDVI filters on map view to see the plant health an slope coefficient of the corresponding point.</span>
    </div>
    <div style="height: 140px; width: 100%; padding-top: 30px">
      <v-card style="border-radius: 8px; background-color: #f8f5fc" width="100%" height="60" class="pt-1 px-2">
        <v-layout class="fill-height" align-center justify-center>
          <v-layout style="width: 400px" class="fill-height px-2" align-center>
            <div class="pr-2" style="font-size: 14px; color: #893FF2; font-weight: 600" v-if="Object.keys(ndviImage).length !== 0">
              NDVI
              <br>
              VISIBILITY
            </div>
            <v-slider
                v-if="Object.keys(ndviImage).length !== 0"
                @change="updateOpacity"
                class="mt-5 pl-4"
                v-model="ndviImage.opacity"
                :max="1"
                color="#893FF2"
                step="0.1"
                tick-size="4"
            >
            </v-slider>
            <h3  v-if="Object.keys(ndviImage).length === 0">No Data</h3>
            <div class="pl-2" style="font-size: 14px; color: #893FF2; font-weight: 600" v-if="Object.keys(ndviImage).length !== 0">
              MAP VIEW
              <br>
              VISIBILITY
            </div>
          </v-layout>
        </v-layout>
      </v-card>
    </div>
    <div style="width: 100%; height: 50px" class="pt-1 pb-2">
      <v-layout class="fill-height" align-center>
        <span style="font-size: 16px; font-weight: bolder; color: #893FF2">NDVI INDEX</span>
        <v-spacer/>
        <div style="width: 120px">
          <v-select
              solo
              background-color="#f8f8f800"
              @change="getImageData"
              dense
              hide-details
              v-model="year"
              :items="years"
          ></v-select>
        </div>
      </v-layout>
    </div>
    <v-layout style="border: 1px solid lightgray">
      <canvas id="ndviChart" style="height:100%; width: 100%"></canvas>
    </v-layout>
    <div style="width: 100%; height: 50px" class="pt-2">
      <div class="d-flex justify-end">
        <span style="font-size: 16px; font-weight: bolder; color: #893FF2">TIME</span>
      </div>
    </div>
    <div style="width: 100%; height: 100px" class="px-0 pt-2">
      <v-card style="border-radius: 8px" width="100%" height="32" class="pt-1 px-2">
        <v-slider
            v-if="monthLabels.length"
            @change="changeMonth"
            class="monthRanger"
            v-model="month"
            :tick-labels="monthLabels"
            :min="1"
            :max="monthLabels.length"
            step="1"
            ticks="always"
            tick-size="4"
        ></v-slider>
      </v-card>
    </div>
  </v-layout>
</template>

<script>
import {
  inspectData,
  getImageries, getFalseColor
} from "@/backend";
import TimeSeriesMap from "@/components/imagery/TimeSeriesMap";
import Chart from "chart.js/auto";
import MonthTransform from "@/ultis/converMonth";
import randomColor from "@/ultis/randomColor";
import ConvertMonth from "@/ultis/converMonth";
export default {
  name: "PopupTimeSeries",
  components: {TimeSeriesMap},
  data () {
    return {
      years: [],
      year: new Date().getFullYear(),
      opacity: 1,
      month: 1,
      monthLabels: [],
      slope: undefined,
      loading: false,
      timeSeriesDialog: false,
      ndviChart: undefined,
      falseImage: undefined,
      ndviImage: {},
      mosaicImage: [],
      mosaicImageSelected: undefined
    }
  },
  props: {
    dataSource: {}
  },
  mounted() {
    let year = new Date().getFullYear()
    for (let i = 2021; i <= parseInt(year); i++) {
      this.years.push(i)
    }
    this.getImageData()
  },
  watch: {
    dataSource (val) {
      this.getImageData()
    }
  },
  methods: {
    openDialog() {
      this.timeSeriesDialog = true
    },
    async getDataTimeSeries (longitude, latitude) {
      try {
        if (Object.keys(this.ndviImage).length === 0 && Object.getPrototypeOf(this.ndviImage) === Object.prototype) return
        this.loading = true
        const res = await inspectData({
          source: this.dataSource,
          year: this.year,
          longitude: longitude,
          latitude: latitude
        })
        this.displayChart(res.data.data.slope, res.data.data)
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getFalseImage () {
      let month
      if (this.month < 10) month = `0${this.month}`
      else month = this.month
      try {
        const res = await getFalseColor({
          month: month,
          year: this.year,
          source: this.dataSource
        })
        let image = res.data.data
        image['id'] = 'false-' + image.id
        image['name'] = image.name + '_false_color'
        image['selected'] = true
        image['isMosaic'] = true
        image['layoutRight'] = true
        image['opacity'] = 1
        image['layoutLeft'] = true
        image['displayLabel'] = true
        image['color'] = randomColor.getColor()
        if (image.list_values) image.list_values.forEach(value => {
          value['display'] = true
        })
        image['map_type'] = 'raster_image'
        this.$emit('changeMosaicImage', JSON.parse(JSON.stringify(image)), this.falseImage)
        this.falseImage = image
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    updateOpacityValue (layer, opacity) {
      if (this.ndviImage.id === layer.id) {
        this.ndviImage.opacity = opacity
        const tmpImage = JSON.parse(JSON.stringify(this.ndviImage))
        this.ndviImage = undefined
        this.ndviImage = tmpImage
      }
    },
    updateOpacity (value) {
      this.$emit('updateOpacity', this.ndviImage)
    },
    changeMonth (month) {
      const oldImage = JSON.parse(JSON.stringify(this.mosaicImageSelected))
      this.mosaicImageSelected = this.mosaicImage.some(val => parseInt(val.month) === month) ? this.mosaicImage.find(val => parseInt(val.month) === month) : this.mosaicImageSelected
      if (this.mosaicImageSelected) this.$emit('changeMosaicImage', this.mosaicImageSelected, oldImage)
      this.getFalseImage()
    },
    async getImageData() {
      try {
        this.monthLabels = []
        this.$emit('resetLayer')
        this.loading = true
        const res = await getImageries({
          year: this.year,
          source: this.dataSource
        })
        this.$nextTick(async () => {
          this.ndviImage = res.data.data.slope ? res.data.data.slope : {}
          if (res.data.data.slope) {
            this.ndviImage['selected'] = true
            this.ndviImage['isSlope'] = true
            this.ndviImage['layoutRight'] = true
            this.ndviImage['opacity'] = 1
            this.ndviImage['layoutLeft'] = true
            this.ndviImage['displayLabel'] = true
            this.ndviImage['color'] = randomColor.getColor()
            if (this.ndviImage.list_values) this.ndviImage.list_values.forEach(value => {
              value['display'] = true
            })
            this.ndviImage['map_type'] = 'raster_image'
            this.ndviImage.list_values.forEach(val => {
              val['display'] = true
            })
          }
          this.mosaicImage = res.data.data.mosaic
          if (this.mosaicImage.length) this.mosaicImage.forEach(image => {
            this.monthLabels.push(this.convertMonthText(image.month))
            image['selected'] = true
            image['isMosaic'] = true
            image['layoutRight'] = true
            image['opacity'] = 1
            image['layoutLeft'] = true
            image['displayLabel'] = true
            image['color'] = randomColor.getColor()
            if (image.list_values) image.list_values.forEach(value => {
              value['display'] = true
            })
            image['map_type'] = 'raster_image'
          })
          this.mosaicImageSelected = res.data.data.mosaic.find(val => parseInt(val.month) === this.month)
          if (this.mosaicImageSelected) this.$emit('changeMosaicImage', JSON.parse(JSON.stringify(this.mosaicImageSelected)))
          await this.getFalseImage()
          if (res.data.data.slope) this.$emit('addTimeSeriesImagelayer', JSON.parse(JSON.stringify(this.ndviImage)))
        })
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    convertMonth (monthArray) {
      return MonthTransform.transformMonthTextArray(monthArray)
    },
    createChart (chartData) {
      let ctx = document.getElementById('ndviChart');
      const data = {
        labels: this.convertMonth(chartData.labels),
        datasets: [{
          type: 'line',
          label: '',
          data: chartData.values,
          fill: 'start',
          backgroundColor : "rgba(137, 63, 242, 0.2)",
          borderColor: '#893FF2',
          lineTension: 0.2
        }]
      }
      this.ndviChart = new Chart(ctx, {
        data: data,
        options: {
          plugins: {
            legend: { display: false }
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel;
              }
            }
          }
        }
      })
    },
    displayChart (slope, chartData) {
      this.slope = slope
      if (!this.ndviChart) this.createChart(chartData)
      else this.updateChart(chartData)
    },
    updateChart (chartData) {
      this.ndviChart.data.labels = this.convertMonth(chartData.labels)
      this.ndviChart.data.datasets.forEach((dataset) => {
        dataset.data = chartData.values
      })
      this.ndviChart.update()
    },
    convertMonthText(month) {
      return ConvertMonth.transformMonthText(month)
    }
  }
}
</script>

<style scoped>

</style>
<style>
.v-tabs-bar {
  height: 35px !important;
}
</style>
