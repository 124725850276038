<template>
  <v-layout style="width: 100%; height: 100%">
    <Log/>
  </v-layout>
</template>

<script>
import Log from '@/components/activity/Log'

export default {
    components: { Log }
}
</script>
