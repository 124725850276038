<template>
  <div class="d-flex fill-height justify-center flex-column pt-4" style="width: 100%; background-color: #f4ecfd">
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-layout style="width: 100%; height: 100%; background-color: #f4ecfd; position: relative" column>
      <v-layout style="width: 100%; height: 100%;" class="pa-0">
        <v-layout column style="height: 100%; background-color: #f4ecfd;" align-center>
          <v-card style="border-radius: 10px; position: absolute; top: 60px; right: 10px; z-index: 3" width="400px" height="35" class="px-2 mb-4">
            <v-layout justify-center align-center class="fill-height" style="height: 100%">
              <v-btn-toggle
                  dense
                  v-model="mapStatus"
                  mandatory
                  color="deep-purple accent-3"
                  group
              >
                <v-btn width="85" min-height="0" height="26" value="overlay"
                       :style="{'border': mapStatus === 'overlay'? '1px solid #893FF2' : '', 'border-radius': mapStatus === 'overlay'? '8px' : '0'}">
                  <span style="padding-top: 3px; font-size: 12px">Overlay</span>
                </v-btn>

                <v-btn width="85" min-height="0" height="26" value="slide"
                       :style="{'border': mapStatus === 'slide'? '1px solid #893FF2' : '', 'border-radius': mapStatus === 'slide'? '8px' : '0'}">
                  <span style="padding-top: 3px; font-size: 12px">Slide</span>
                </v-btn>
                <v-btn width="85" min-height="0" height="26" value="sync"
                       :style="{'border': mapStatus === 'sync'? '1px solid #893FF2' : '', 'border-radius': mapStatus === 'sync'? '8px' : '0'}">
                  <span style="padding-top: 3px; font-size: 12px">Sync</span>
                </v-btn>
                <v-btn width="85" min-height="0" height="26" value="time"
                :style="{'border': mapStatus === 'time'? '1px solid #893FF2' : '', 'border-radius': mapStatus === 'time'? '8px' : '0'}">
                <span style="padding-top: 3px; font-size: 12px">Time</span>
                </v-btn>
              </v-btn-toggle>
            </v-layout>
          </v-card>
          <div class="d-flex flex-column" style="width: 450px; height: 95%; position: absolute; left: 0; top: 40px; z-index: 3;" v-if="isShow">
            <div
                 style="border-bottom-right-radius: 30px; overflow: hidden; background-color: white; width: 450px; height: 100%; border-top-right-radius: 12px; border-top: 2px solid #deccf8; border-right: 2px solid #deccf8; padding-right: 0px"
                 class="pt-0 flex elevation-24">
              <DataSelection
                  ref="dataSelection"
                  v-if="mapStatus !== 'time'"
                  :groupType.sync="groupType"
                  :groups.sync="groupImage"
                  :areas.sync="aois"
                  @input="changeGroupBy"
                  @hideSideBar="isShow = false"
                  @changeListLayer="changeListLayer"
                  @changeMonth="changeMonth"
                  :fromMonth.sync="fromMonth"
                  :toMonth.sync="toMonth"
              />
              <PopupTimeSeries
                  v-else
                  ref="timeSeries"
                  :dataSource.sync="currentDatasource"
                  @resetLayer="resetLayer"
                  @changeMosaicImage="changeMosaicImage"
                  @updateOpacity="updateOpacity"
                  @addTimeSeriesImagelayer="addSlopeImage"
              />
            </div>
            <div style="height: 50px; width: 100%; flex: none">
              <img src="@/assets/images/reverse-rounded-footer.png" style="margin-left: -6px">
            </div>
          </div>
          <BaseMap @inspectImage="inspectImage" @changeListLayer="changeListLayer" ref="mapView" :mode.sync="mapStatus" :dataSource.sync="currentDatasource"/>
          <LayerControl
              @toggleLabel="(status) => $refs.mapView.toggleLabel(status)"
              @changeViewImage="changeViewImage"
              @changeDisplayImage="(image, beforeId) => $refs.mapView.changeDisplayImage(image, beforeId)"
              @changeViewImageLeftMap="changeViewImageLeftMap"
              @changeViewImageRightMap="changeViewImageRightMap"
              @zoomToImage="zoomToImage"
              @updateOpacity="changeOpacityValue"
              @closeLayerControl="showLayerControl = false"
              @newMoveLayer="newMoveLayer"
              @removeLayer=" (layer) => $refs.mapView.removeLayer(layer)"
              :layers.sync="layers"
              :areas.sync="aois"
              ref="layerControl"
              :mapStatus="mapStatus"
              v-if="showLayerControl"
              style="position: absolute; bottom: 180px; right: 10px"
          >
          </LayerControl>
        </v-layout>
      </v-layout>
    </v-layout>
    <v-btn
        fab
        :outlined="!showLayerControl"
        @click="showLayerControl = !showLayerControl"
        :style="{'background-color': showLayerControl ? '#893FF2' : '#fff' }"
        style="position: absolute; bottom: 140px; right: 10px" :color="showLayerControl ? '#893FF2' : '#fff'" height="40" width="40" min-width="0">
      <span>
        <v-icon :color="showLayerControl ? '#fff' : '#893FF2'">mdi-layers</v-icon>
      </span>
    </v-btn>
    <v-btn @click="isShow = true" outlined style="background-color: white; position: absolute; top: 30%; left: 0" color="#893FF2" height="120" width="30" min-width="0">
      <span style="transform:rotate(-90deg)">SideBar
        <v-icon color="#893FF2">mdi-chevron-double-down</v-icon>
      </span>
    </v-btn>
  </div>
</template>
<script>
import {
  getGeom,
  getAOI,
  getImageries, inspectData, inspectImageData,
} from "@/backend"
import {debounce} from "lodash"
import {mapState} from "@/store/ults"
import PopupCreateUser from "@/components/user/CreateUser"
import BaseMap from "@/components/imagery/BaseMap"
import PopupTimeSeries from "@/components/imagery/TimeSeriesView"
import LayerControl from "@/components/imagery/LayerControl";
import DataSelection from "@/components/imagery/DataSelection";
import randomColor from "@/ultis/randomColor";
import bbox from "@turf/bbox";
// import numberFormat from "@/ultis/comma";
// import Store from "@/store";

export default {
  components: {
    DataSelection,
    LayerControl,
    PopupTimeSeries,
    BaseMap,
    PopupCreateUser
  },
  data() {
    return {
      aois: [],
      refData: undefined,
      groupType: 'type',
      showLayerControl: true,
      isShow: true,
      mode: 'app/image-detail',
      compareMap: false,
      allImage: [],
      tab: null,
      items: [
        'All', 'Satellite View', 'Result obtabined'
      ],
      layers: [],
      mapStatus: 'overlay',
      loading: false,
      users: [],
      fromMonth: '2021-01',
      toMonth: this.getToDate(),
      months: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
    }
  },
  props: {
    currentDatasource: {}
  },
  computed: {
    ...mapState("auth", ["currentUser"]),
    groupImage () {
      let groups = []
      switch (this.groupType) {
        case 'type':
          this.allImage.forEach(image => {
            if (!groups.length) {
              let name = ''
              switch (image.type.replaceAll('_', ' ')) {
                case 'classification': name = 'Green Cover'
                  break
                case 'cloud': name = 'Cloud Cover'
                  break
                case 'green cover change': name = 'Green Cover Change'
                  break
                case 'mosaic': name = 'Monthly Image Mosaic'
                  break
                case 'forest cover area': name = 'Forest Cover'
                  break
                case 'plant health': name = 'Plant Health NDVI'
                  break
                case 'green density': name = 'Plant Density'
                  break
                 case 'forest cover change': name = 'Forest Cover Change'
                  break
                default:
                    name = image.type.replaceAll('_', ' ')
              }
              groups.push({
                name: name,
                type: image.type,
                images:[image]
              })
            } else {
              let name = ''
              switch (image.type.replaceAll('_', ' ')) {
                case 'classification': name = 'Green Cover'
                  break
                case 'cloud': name = 'Cloud Cover'
                  break
                case 'green cover change': name = 'Green Cover Change'
                  break
                case 'mosaic': name = 'Monthly Image Mosaic'
                  break
                case 'forest cover area': name = 'Forest Cover'
                  break
                case 'plant health': name = 'Plant Health NDVI'
                  break
                case 'green density': name = 'Plant Density'
                  break
                 case 'forest cover change': name = 'Forest Cover Change'
                  break
                default:
                  name = image.type.replaceAll('_', ' ')
              }
              let index = groups.findIndex(group => group.type === image.type)
              if (index >= 0) groups[index].images.push(image)
              else groups.push({
                name: name,
                type: image.type,
                images: [image]
              })
            }
          })
          groups.sort(function(a, b) {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
          })
          break
        case 'month':
          this.allImage.forEach(image => {
            if (!groups.length) {
              groups.push({
                name: 'data of ' + image.month + '/' + + image.year,
                month: image.month,
                year: image.year,
                images:[image]
              })
            } else {
              let index = groups.findIndex(group => (group.month === image.month && group.year === image.year))
              if (index >= 0) groups[index].images.push(image)
              else groups.push({
                name: 'data of ' + image.month + '/' + + image.year,
                month: image.month,
                year: image.year,
                images:[image]
              })
            }
          })
          groups.sort(function(a, b) {
            if (a.year < b.year && a.month < b.month) return -1
            if (a.year > b.year && a.month > b.month) return 1
            return 0
          })
          break
      }
      groups.forEach(group => {
        group.images.sort(function(a, b) {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
      })
      return groups
    },
  },
  watch: {
    currentDatasource (val) {
      this.getAOI()
      this.getData()
    },
    mapStatus (val) {
      this.resetLayer()
    }
  },
  created() {
  },
  mounted() {
    this.getData()
    this.getAOI()
    // this.displayRefImage()
  },
  methods: {
    getToDate () {
      let year = new Date().getFullYear()
      let month = new Date().getMonth() > 0 ? new Date().getMonth() : '1'
      if (month < 10) month = '0' + month
      return year + '-' + month
    },
    resetLayer () {
      let timeLayer = []
      this.layers.forEach(layer => {
        if (layer.isMosaic || layer.isSlope) timeLayer.push(layer)
      })
      timeLayer.forEach(layer => {
        this.$refs.layerControl.removeLayer(layer)
      })
      // this.$refs.mapView.resetMap()
      switch (this.mapStatus) {
        case 'slide': this.$refs.mapView.changeCompare(true)
          break
        case 'sync': this.$refs.mapView.syncMap()
          break
        default:
          this.$refs.mapView.changeCompare(false)
          break
      }
    },
    addSlopeImage (image) {
      if (!this.layers.some(val => val.id === image.id)) this.changeListLayer(image)
    },
    changeMosaicImage (newImage, oldImage) {
      if (oldImage) {
        let idx = this.layers.findIndex(layer => layer.id === oldImage.id)
        if (idx >= 0) {
          this.layers.splice(idx, 0, newImage)
          this.$refs.mapView.addImageLayer(newImage, oldImage.id)
          this.$refs.layerControl.removeLayer(oldImage)
        }
      }
      else if (!this.layers.some(layer => layer.id === newImage.id)) {
        this.layers.unshift(newImage)
        this.$refs.mapView.addImageLayer(newImage)
      }
    },
    changeOpacityValue (layer, opacity) {
      if (this.$refs.timeSeries) this.$refs.timeSeries.updateOpacityValue(layer, opacity)
      this.$refs.mapView.updateOpacity(layer, opacity)
    },
    updateOpacity (layer) {
      this.$refs.layerControl.updateOpacity(layer)
    },
    async inspectImage (longitude, latitude) {
      if (this.mapStatus === 'time') await this.$refs.timeSeries.getDataTimeSeries(longitude, latitude)
    },
    async getAOI () {
      try {
        this.loading = true
        const res = await getAOI({source: this.currentDatasource})
        const data = res.data.data
        data.forEach(value => {
          value['id'] = `AOI-${value.id}`
          value['selected'] = false
          value['layoutRight'] = true
          value['opacity'] = 1
          value['layoutLeft'] = true
          value['displayLabel'] = false
          value['map_type'] = 'vector'
          value['color'] = randomColor.getColor()
          value['bbox'] = bbox(value.geometry)
        })
        this.aois = data
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    changeGroupBy (val) {
      this.groupType = val
    },
    async changeListLayer (currentLayer) {
      if (!this.layers.some(layer => layer.id === currentLayer.id)) {
        this.layers.unshift(currentLayer)
        switch (currentLayer.map_type) {
          case 'raster_image': {
            await this.$refs.mapView.addImageLayer(currentLayer)
          }
            break
          case 'vector':
            if (!currentLayer.geometry) {
              try {
                this.loading = true
                const res = await getGeom(currentLayer.id)
                currentLayer.geometry = res.data.data
                currentLayer.bbox = bbox(res.data.data)
              } catch (e) {
              } finally {
                this.loading = false
              }
            }
            this.$refs.mapView.addVectorLayer(currentLayer)
            // this.changeVector(currentLayer)
            break
        }
      }
    },
    async getData () {
      this.allImage = []
      if (this.mapStatus !== 'time') {
        try {
          this.loading = true
          const res = await getImageries({
            dateRanger: [this.fromMonth, this.toMonth],
            source: this.currentDatasource
          })
          let data = res.data.data
          data.forEach(image => {
            image['selected'] = true
            image['layoutRight'] = true
            image['opacity'] = 1
            image['layoutLeft'] = true
            image['displayLabel'] = true
            image['map_type'] = image.data_type
            image['color'] = randomColor.getColor()
            if (image.list_values) image.list_values.forEach(value => {
              value['display'] = true
            })
          })
          this.allImage = data
          // this.$refs.mapView.resetMap()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    changeMonth: debounce(function (fromMonth, toMonth) {
      this.fromMonth = fromMonth
      this.toMonth = toMonth
      this.getData()
    }, 500),
    changeViewImageLeftMap (image) {
      image.layoutLeft = !image.layoutLeft
      this.$refs.mapView.changeViewImage(image, 'left')
    },
    changeViewImageRightMap (image) {
      image.layoutRight = !image.layoutRight
      this.$refs.mapView.changeViewImage(image, 'right')
    },
    changeVector (vector) {
      vector.layoutRight = !vector.layoutRight
      if (vector.layoutRight) {
        vector.layoutLeft = true
        this.$refs.mapView.addVectorLayer(vector)
      } else {
        vector.layoutLeft = false
        this.$refs.mapView.removeLayer(vector)
      }
    },
    async newMoveLayer (listImage) {
      this.$refs.mapView.removeAllLayer()
      for (let i = listImage.length - 1; i >= 0; i--) {
        switch (listImage[i].map_type) {
          case 'raster_image': this.$refs.mapView.addImageLayer(listImage[i], undefined, listImage[i].opacity)
            break
          case 'vector': {
            if (!listImage[i].geometry) {
              try {
                const res = await getGeom(listImage[i].id)
                listImage[i].geometry = res.data.data
              } catch (e) {
              }
            }
            this.$refs.mapView.addVectorLayer(listImage[i], listImage[i].opacity)
          }
            break
        }
      }
    },
    changeViewImage (image) {
      image.layoutRight = !image.layoutRight
      if (image.layoutRight) {
        image.layoutLeft = true
        this.$refs.mapView.addImageLayer(image)
      } else {
        image.layoutLeft = false
        this.$refs.mapView.removeLayer(image)
      }
    },
    zoomToImage (image) {
      this.$refs.mapView.submitZoom(image.bbox)
    }
  }
}
</script>
<style scoped>
/deep/
.select-month .v-select__slot {
  border-bottom: 1px #893FF2 solid;
}
/deep/
.select-month .v-select__selections {
  font-weight: bold;
}
/deep/
.v-chip__content {
  width: 180px !important;
}
/deep/
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: inset 0px 4px 6px -5px rgb(50 50 50 / 75%),
  inset 0 -4px 8px -5px rgb(50 50 50 / 0%);
}
/deep/
.order-table th {
  background-color: #893FF2 !important;
  color: white !important;
}
/deep/
.list-image th {
  background-color: #6686a3 !important;
}
</style>
<style>
.monthRanger .v-slider__tick{
  border-left: 0.5px solid !important;
  height: 10px !important;
  position: absolute !important;
  top: -5px !important;
  width: 1px !important;
}
.monthRanger .v-slider__tick-label {
  padding-top: 20px;
  font-size: 11px !important;
}
.v-tabs-bar {
  height: 35px !important;
}
</style>
