<template>
  <div class="d-flex fill-height justify-center flex-column px-8" style="width: 100%" v-if="currentUser.role === 'ADMIN'">
    <div style="width: 100%; height: 155px; border-bottom: 1px solid lightgrey" class="d-flex align-center justify-center flex-column">
      <v-layout align-center>
        <h1 class="d-flex"><v-icon color="#333333" size="40" class="mr-1">mdi-account-multiple</v-icon>Login History</h1>
      </v-layout>
      <v-container fluid class="flex-fill overflow-y-auto" style="height: 20px; max-width: 1400px">
        <v-layout fill-height align-center justify-end>
          <div style="width: 300px; height: 100%" class="mr-2">
            <v-layout fill-height align-center>
              <div class="mr-2" style="width: 90px">From date</div>
              <DatePicker solo @input="getData" v-model="param.from_date" label="From date"/>
            </v-layout>
          </div>
          <div style="width: 300px; height: 100%" class="ml-2">
            <v-layout fill-height align-center>
              <div class="mr-2" style="width: 60px">To date</div>
              <DatePicker solo @input="getData" v-model="param.to_date" label="To date"/>
            </v-layout>
          </div>
        </v-layout>
      </v-container>
    </div>
    <v-container fluid class="flex-fill overflow-y-auto" style="height: calc(100vh - 220px); max-width: 1400px">
      <v-card height="100%" flat>
        <v-data-table
            height="100%"
            fixed-header
            style="height: 77%; border: 1px lightgrey solid"
            group-by="date"
            :headers="userHeaders"
            :items="logs"
            :loading="loading"
            :items-per-page="15"
            class="row-pointer order-table"
            hide-default-footer
            :server-items-length="totalItem"
            loading-text="Loading... Please wait"
            :options.sync="options"
            :page.sync="page"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at ? new Date(item.created_at).toLocaleString("en-GB") : ''}}
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.causer ? item.causer.name : ''}}
          </template>
          <template v-slot:[`item.email`]="{ item }">
            {{ item.causer ? item.causer.email : ''}}
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <v-chip style="width: 80px; text-align: center" small :color="item.type === 'success' ? 'success' : 'error'">{{ item.type }}</v-chip>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination class="ma-3 mt-6" :total-visible="5" v-model="page" :length="pageCount" id="task_pagination"></v-pagination>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {
  getActivityLog,
} from "@/backend";
import {mapState} from "@/store/ults";
import DatePicker from "../DatePicker.vue";
export default {
  components: {
    DatePicker
  },
  data() {
    return {
      options: {},
      page: 1,
      totalItem: 100,
      pageCount: 0,
      loading: false,
      param: {
        from_date: this.getFromDate(),
        to_date: this.getCurrentDate()
      },
      userHeaders: [
        {text: "IP", align: "left", sortable: true, value: "ip",},
        { text: "User", value: "name", sortable: false, align: "left" },
        { text: "Email", value: "email", sortable: false, align: "left" },
        { text: "Status", value: "type", sortable: false, align: "start" },
        { text: "Login at", value: "created_at", sortable: true, align: "start" },
      ],
      logs: []
    };
  },
  computed: {
    ...mapState("auth", ["currentUser"])
  },
  watch: {
    options: {
      handler () {
        this.getData()
      },
      deep: true
    }
  },
  created() {
    if (this.currentUser.role !== 'ADMIN') this.$router.push({ path: '/app' })
  },
  methods: {
    getFromDate() {
      let currentDate = new Date()
      let month = currentDate.getMonth() < 9 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth()
      let year = currentDate.getFullYear()
      return year + '-' + month + '-' + '01'
    },
    getCurrentDate() {
      let currentDate = new Date()
      let day = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate()
      let month = currentDate.getMonth() < 9 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1
      let year = currentDate.getFullYear()
      return year + '-' + month + '-' + day
    },
    async getData() {
      try {
        this.loading = true
        let sort
        if (this.options.sortBy.length && this.options.sortBy.length) {
          if (this.options.sortDesc[0]) sort = this.options.sortBy[0]
          else sort = '-' + this.options.sortBy[0]
        }
        let param = {
          ...this.param,
          ...{per_page: this.options.itemsPerPage, page: this.options.page, sort: sort}
        };
        const res = await getActivityLog(param)
        this.logs = res.data.data
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
  },
};
</script>
<style scoped>
/deep/
.v-chip__content {
  width: 180px !important;
}
/deep/
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: inset 0px 4px 6px -5px rgb(50 50 50 / 75%),
  inset 0px -4px 8px -5px rgb(50 50 50 / 0%);
}
/deep/
.order-table th {
  background-color: #893FF2 !important;
  color: white !important;
}
/deep/
.list-image th {
  background-color: #6686a3 !important;
}
</style>
